import React from 'react'
import { useField } from 'formik'

const Input = ({
  name,
  placeholder,
  label,
  type,
  isFlex //If the parent container has flex properties
} : {
  name: string,
  label: string,
  type: string,
  isFlex?: boolean
  placeholder?: string,
}) => {
  const [field, meta] = useField({ name });
  const className = isFlex ? 'mt-2 sm:w-full md:w-[48%] lg:w-[48%]' : '';

  return (
    <div className={className}>
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-dark-blue">
        {label}
        <div className="mt-2">
          <input {...field} id={name} name={name} placeholder={placeholder} type={type} autoComplete={name} className="block w-full rounded-md border-0 px-2.5 py-1.5 text-dark-blue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
        </div>
      </label>
      <small className="text-sm mt-0.5 text-red-400">{meta.touched && meta.error}</small>
    </div>
  )
}

export default Input