import React from 'react';
// import Logo from 'assets/imgs/transparent-logo.png';
import { Formik, Form, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import { ThunkDispatch } from '@reduxjs/toolkit';
import Input from '../components/Input';
import { create } from '../redux/vehicle/vehicleSlice';
import MainLayout from '../layout/MainLayout';
import Select from '../components/Select';
import { useQuery } from '@tanstack/react-query';
import { getDepartments } from '../api';


const Vehicle = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const state = useSelector((state: any) => state);
  const initialValues: {
    name: string,
    engineCapacity: string,
    fuelTankCapacity: string,
    numberPlate: string,
    fuelConsumption: string,
    fuelConsumptionUnits: string,
    fuelType: string,
    department: string,
    mileage: string
  } = {
    name: '',
    engineCapacity: '',
    fuelTankCapacity: '',
    numberPlate: '',
    fuelConsumption: '0',
    fuelConsumptionUnits: '',
    fuelType: '',
    department: '',
    mileage: ''
  };

  const { data: departments } = useQuery({
    queryFn: () => getDepartments(state.auth.user.token),
    queryKey: ['departments']
  });

  const validator = object({
    name: string()
      .required('vehicle name is a required field'),
    engineCapacity: string()
      .required('Engine capacity is a required field.'),
    fuelTankCapacity: string()
      .required('Fuel Tank capacity is a required field.'),
    numberPlate: string()
      .required('Number Plate is a required field.'),
    fuelType: string()
      .required('Fuel Type is a required field.'),
    department: string()
      .required('Department is a required field.'),
    mileage: string()
      .required('Mileage is a required field.'),
    fuelConsumption: string()
      .required('Fuel consumption is a required field.'),
    fuelConsumptionUnits: string()
      .required('Fuel consumption units is a required field.'),
  });

  const onSubmit =
    (values: {
      name: string,
      engineCapacity: string,
      fuelTankCapacity: string,
      numberPlate: string,
      fuelConsumption: string,
      fuelConsumptionUnits: string,
      fuelType: string,
      department: string,
      mileage: string
    }, actions: FormikHelpers<{
      name: string,
      engineCapacity: string,
      fuelTankCapacity: string,
      numberPlate: string,
      fuelConsumption: string,
      fuelConsumptionUnits: string,
      fuelType: string,
      department: string,
      mileage: string
    }>) =>
      dispatch(create(Object.assign(values, { token: state.auth.user.token })))
        .then((res) => res.type.includes('fulfilled') && actions.resetForm())

  const fuelTypes = [
    { value: 'diesel', label: 'Diesel' },
    { value: 'petrol', label: 'Petrol' },
  ];

  const findDepartment = (array: [], id: string): { id: string, name: string } =>
    array?.find((department: { id: string }) => department?.id === id) ?? ({ id: '', name: '' });

  return (
    <MainLayout>
      <div className="flex min-h-full flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {/* <img className="mx-auto h-20 w-auto" src={Logo} alt="Your Company" /> */}
          <h1 className="text-center text-2xl font-bold leading-9 tracking-tight text-dark-blue">Create Vehicle</h1>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Formik
            initialValues={initialValues}
            validationSchema={validator}
            onSubmit={onSubmit}
          >
            {
              ({
                values,
              }) => (
                <Form className="space-y-6">

                  <Input name="name" label="Vehicle Name" type="text" />

                  <Input name="engineCapacity" label="Engine Capacity" type="text" />

                  <Input name="fuelTankCapacity" label="Fuel Tank Capacity" type="text" />

                  <Input name="fuelConsumption" label="Fuel Consumption" type="text" />

                  <Input name="fuelConsumptionUnits" label="Fuel Consumption Units" type="text" />

                  <Input name="numberPlate" label="Number Plate" type="text" />

                  <Select
                    name="department"
                    options={departments?.map((department: { name: string, id: string }) => ({ value: department.id, label: department.name }))}
                    label="Select a Department"
                    value={values.department ? { label: findDepartment(departments, values.department).name, value: findDepartment(departments, values.department).id } : null} />

                  <Input name="mileage" label="Mileage" type="text" />

                  <Select
                    name="fuelType"
                    options={fuelTypes}
                    label="Select Fuel Type"
                    value={values.fuelType ? fuelTypes.find(item => item.value === values.fuelType) || { label: values.fuelType, value: values.fuelType } : null} />

                  <div>
                    <button disabled={state.vehicle.isLoading} type="submit" className={`flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${state.vehicle.isLoading ? 'cursor-not-allowed' : ''}`}>Create</button>
                  </div>
                </Form>
              )
            }
          </Formik>
        </div>
      </div>
    </MainLayout>
  );
};

export default Vehicle;
