import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { approveRequisition, disburseRequisition, downloadReciept, downloadCSVReport, getRequisitons, rejectRequisition } from '../api';
import Table from '../components/Table';
import Button, { IconButton } from '../components/Button';
import MainLayout from '../layout/MainLayout';
import { toastMessage } from '../utlls/toaster';
import Badge from '../components/Badge';
import { CrudModal } from '../components/Modal';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import Pagination from '../components/Pagination';
import DatePicker from '../components/DatePicker';

const Requisitions = () => {
  const state = useSelector((state: any) => state);
  const [docId, setDocId] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [startDate, setStartDate] = useState<Date>(moment().toDate());
  const [endDate, setEndDate] = useState<Date>(moment().toDate())
  const [page, setPage] = useState<number>(1);

  const queryClient = useQueryClient();

  const [isLoadingDisburse, setIsLoadingDisburse] = useState<boolean>(false);
  const [isLoadingApprove, setIsLoadingApprove] = useState<boolean>(false);
  const [isGeneratingReport, setIsGeneratingReport] = useState<boolean>(false);

  const calcDiff = (opening: number, closing: number) => closing - opening;

  const { data: requisitions } = useQuery({
    queryKey: ['requisitions', page],
    queryFn: () => getRequisitons(state.auth.user.token, page),
  });

  const approve = async (id: string) => {
    try {
      setIsLoadingApprove(true);
      const data = await approveRequisition(state.auth.user.token, id);

      toastMessage(data);
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoadingApprove(false);
      queryClient.invalidateQueries({ queryKey: ['requisitions'] })
    }
  }

  const disburse = async (id: string) => {
    try {
      setIsLoadingDisburse(true);
      const data = await disburseRequisition(state.auth.user.token, id);

      toastMessage(data);
    } catch (error: any) {
      if (error.response) {
        return toastMessage(error.response.data.err, 'error');
      }
      toastMessage(error.message, 'error');
    } finally {
      setIsLoadingDisburse(false);
      queryClient.invalidateQueries({ queryKey: ['requisitions'] })
    }
  }

  const onReject = (id: string) => setDocId(id);
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => setReason(e.target.value);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!reason) return toastMessage('You cannot send an empty reason for rejecting the requisition.', 'error');
    try {
      setIsLoadingApprove(true);
      const data = await rejectRequisition(state.auth.user.token, docId, reason);

      toastMessage(data);
      setReason('');
      setDocId('');
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingApprove(false);
      queryClient.invalidateQueries({ queryKey: ['requisitions'] })
    }
  }

  const onDownload = async (id: string) => {
    try {
      const data = await downloadReciept(state.auth.user.token, id);

      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: 'application/pdf'
        })
      );

      // uses the download attribute on a temporary anchor to trigger the browser
      // download behavior. if you need wider compatibility, you can replace this
      // part with a library such as filesaver.js
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", id + '.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

    } catch (error: any) {
      if (error.response) {
        toastMessage(error.response.data.err, 'error');
      }

      toastMessage(error.message, 'error');
    }
  }

  const onGenerateReport = async () => {
    try {
      setIsGeneratingReport(true);
      const data = await downloadCSVReport(state.auth.user.token, startDate, endDate);

      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: 'text/csv;charset=utf-8'
        })
      );

      // uses the download attribute on a temporary anchor to trigger the browser
      // download behavior. if you need wider compatibility, you can replace this
      // part with a library such as filesaver.js
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${new Date().getDate()}-${(new Date().getMonth() + 1)}-${new Date().getFullYear()}.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

    } catch (error: any) {
      if (error.response) {
        toastMessage(error.response.data.err, 'error');
      }

      toastMessage(error.message, 'error');
    } finally {
      setIsGeneratingReport(false);
    }
  }

  const tHeadData: {
    title: string
    maxWidth: string
    align?: 'center' | 'left' | 'right'
  }[] =
    [
      {
        title: 'Driver Name',
        maxWidth: 'auto'
      },
      {
        title: 'Created By',
        maxWidth: 'auto'
      },
      {
        title: 'Vehicle Name',
        maxWidth: '200px'
      },
      {
        title: 'Created On',
        maxWidth: '150px'
      },
      {
        title: 'Closing Meter',
        maxWidth: '100px',
        align: 'center'
      },
      {
        title: 'Opening Meter',
        maxWidth: '100px',
        align: 'center'
      },
      {
        title: 'Difference',
        maxWidth: '100px',
        align: 'center'
      },
      {
        title: 'Litres Requested',
        maxWidth: '120px',
        align: 'center'
      },
      {
        title: 'Consumption',
        maxWidth: '120px',
        align: 'center'
      },
      {
        title: 'Destination',
        maxWidth: '120px',
        align: 'center'
      },
      {
        title: 'Status',
        maxWidth: '150px',
        align: 'center'
      },
      {
        title: 'Actions',
        maxWidth: 'auto',
        align: 'center'
      }
    ]

  const TBody = ({
    data
  }:{
    data: any
  }) => {
    const role = state.auth.user.role.name.toLowerCase();

    return (
      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th
          className="px-6 py-4 text-xs text-gray-900 whitespace-nowrap dark:text-white"
          dangerouslySetInnerHTML={{ __html: data?.driverName }}></th>
        <th scope="row" className="px-6 py-4 text-xs font-medium text-gray-900 whitespace-nowrap dark:text-white">
          {data?.user?.name}
        </th>
        <td className="px-6 py-4 text-xs" dangerouslySetInnerHTML={{ __html: data?.vehicle?.name}}></td>
        <td className="px-6 py-4 text-xs text-center">
          {moment(data?.createdAt).format('DD/MM/YY')}
        </td>
        <td className="px-6 py-4 text-xs text-center">
          {data?.closingMileage}
        </td>
        <td className="px-6 py-4 text-xs text-center">
          {data?.openingMileage}
        </td>
        <td className="px-6 py-4 text-xs text-center">
          {calcDiff(Number(data?.openingMileage), Number(data?.closingMileage))}
        </td>
        <td className="px-6 py-4 text-xs text-center">
          {data?.litres}
        </td>
        <td className="px-6 py-4 text-xs text-center">
          <span>{ data?.distanceCoverage ? (data?.distanceCoverage / data?.litres).toString().substring(0, 4) : 0 } <span dangerouslySetInnerHTML={{ __html: data?.vehicle?.fuelConsumptionUnits }}></span></span>
          <p>{ data?.tripType && data.tripType }</p>
        </td>
        <td
          className="px-6 py-4 text-xs text-center"
          dangerouslySetInnerHTML={{ __html: data?.destination }}>
        </td>
        <td className="px-6 py-4 text-xs text-center">
          <div className="flex flex-col gap-y-0.5">
            {data?.isRejected && <Badge size="small" colors='bg-red-100 text-red-800' text='Rejected' />}
            {data?.isDisbursed && <Badge size="small" colors='bg-green-100 text-green-800' text="Issued" />}
            {
              (!data?.isRejected && !data?.isDisbursed) &&
              <>
                {
                  data?.hod &&
                  <Badge size="small" colors='bg-blue-100 text-blue-800' text='Pending Issuance' />

                }
                {
                  !data?.supervisor && !data?.hod &&
                  <Badge size="small" colors='bg-purple-100 text-purple-800' text='Pending Supervisor' />
                }
                {
                  !data?.hod &&
                  <Badge size="small" colors='bg-purple-100 text-purple-800' text='Pending HOD' />
                }
              </>
            }
          </div>
        </td>
        <td className="px-6 py-4 text-xs flex gap-x-2 justify-center">
          {
            data?.isRejected ?
              <Badge text='No Actions Available' colors='bg-yellow-100 text-yellow-800' /> :
              <>
                {
                  !data?.isDisbursed &&
                  <>
                    {
                      role === 'hod' && !data?.hod &&
                      <Button
                        type="button"
                        size="small"
                        label="Approve"
                        onClick={() => approve(data?.id)}
                        disabled={data?.hod || isLoadingApprove} />
                    }
                    {
                      role === 'supervisor' && !data?.supervisor &&
                      <Button
                        type="button"
                        size="small"
                        label="Approve"
                        onClick={() => approve(data?.id)}
                        disabled={data?.supervisor || isLoadingApprove} />
                    }
                    {
                      role === 'pump' &&
                      <Button
                        type="button"
                        size="small"
                        label="Disburse"
                        onClick={() => disburse(data?.id)}
                        disabled={(!data?.hod) || (data?.isDisbursed) || isLoadingDisburse} />
                    }

                    {
                      role === 'pump' && !data?.supervisor &&
                      <Button
                        type="button"
                        size="small"
                        colors='bg-red-600 hover:bg-red-400'
                        label="Reject"
                        onClick={() => onReject(data?.id)}
                        disabled={data?.supervisor} />
                    }

                    {
                      role === 'supervisor' && !data?.supervisor &&
                      <Button
                        type="button"
                        size="small"
                        colors='bg-red-600 hover:bg-red-400'
                        label="Reject"
                        onClick={() => onReject(data?.id)}
                        disabled={data?.hod} />
                    }

                    {
                      role === 'hod' && !data?.hod &&
                      <Button
                        type="button"
                        size="small"
                        colors='bg-red-600 hover:bg-red-400'
                        label="Reject"
                        onClick={() => onReject(data?.id)}
                        disabled={data?.hod} />
                    }
                  </>
                }

                {
                  data?.hod &&
                  <IconButton size='small' onClick={() => onDownload(data?.id)} disabled={!data?.isDisbursed}>
                    <ArrowDownTrayIcon width={18} height={18} />
                  </IconButton>
                }
              </>
          }
        </td>
      </tr>
    )
  }

  const TBodyData = () => (
    <>
      {
        requisitions &&
        requisitions?.requisitions?.map((item: any) => <TBody key={item?.id} data={item} />)
      }
    </>
  )

  const setCurrentPage = (page: number | string) => setPage(Number(page));

  const setNextPage = () => {
    const pages = Math.ceil(requisitions?.dataLength / 20) || 0;

    if (page < pages) setPage((prev) => prev + 1);
  };

  const setPrevPage = () => {
    if (page > 1) setPage((prev) => prev - 1);
  };

  const pages = Math.ceil(requisitions?.dataLength / 20) || 0;

  return (
    <MainLayout>
      <CrudModal
        isOpen={Boolean(docId)}
        onClose={() => setDocId('')}
        title="Reject Requisition"
      >
        <form onSubmit={onSubmit} className="p-4 md:p-5">
          <div className="grid gap-4 mb-4 grid-cols-2">
            <div className="col-span-2">
              <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter Reason</label>
              <input onChange={onInputChange} type="text" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Type Reason" />
            </div>
          </div>
          <Button type='submit' label='Submit' colors='bg-blue-600 hover:bg-blue-400' disabled={isLoadingApprove} />
        </form>
      </CrudModal>
      <div className="flex items-end justify-end py-3">
        <div className="min-w-[220px]">
          {
            state.auth.user.role.name.toLowerCase() === 'hod' &&
            <div className='w-100 flex items-end gap-2 flex-col flex-wrap lg:flex-row lg:flex-nowrap'>
              <DatePicker maxDate={moment().toDate()} onChange={(value: any) => setStartDate(value)} value={startDate}>
                <span className="text-gray-500">Start Date: </span>
              </DatePicker>
              <DatePicker maxDate={moment().toDate()} onChange={(value: any) => setEndDate(value)} value={endDate}>
                <span className="text-gray-500">End Date: </span>
              </DatePicker>
              <Button colors='bg-teal-600 hover:bg-teal-400' size='small' type='button' disabled={isGeneratingReport} onClick={onGenerateReport}>Generate Report</Button>
            </div>
          }
        </div>
      </div>
      <Table tHeadData={tHeadData} tBodyData={<TBodyData />} />

      <div className="flex items-end justify-end mt-3">
        <Pagination
          currentPage={page}
          pages={pages}
          setNextPage={setNextPage}
          setPage={setCurrentPage}
          setPrevPage={setPrevPage}
        />
      </div>
    </MainLayout>
  )
}

export default Requisitions