import React from 'react';
// import Logo from 'assets/imgs/transparent-logo.png';
import { Formik, Form, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import { ThunkDispatch } from '@reduxjs/toolkit';
import Input from '../components/Input';
import { fuelPurchase } from '../redux/pump/pumpSlice';
import MainLayout from '../layout/MainLayout';
import { useQuery } from '@tanstack/react-query';
import { getPumps } from '../api';
import Select from '../components/Select';
import { capitalize } from '../utlls/common';
import Button from '../components/Button';

const FuelPurchase = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const state = useSelector((state: any) => state);
  const initialValues: {
    litres: string,
    id: string
  } = {
    litres: '',
    id: ''
  }

  const { data: pumps } = useQuery({
    queryFn: () => getPumps(state.auth.user.token),
    queryKey: ['pumps']
  });

  const validator = object({
    litres: string()
      .required('Litres field is required'),
    id: string()
      .required('Please select a pump to continue')
  });

  const onSubmit =
    (values: {
      litres: string,
      id: string
    }, actions: FormikHelpers<{
      litres: string,
      id: string
    }>) =>
      dispatch(fuelPurchase(Object.assign(values, { token: state.auth.user.token })))
        .then((res) => res.type.includes('fulfilled') && actions.resetForm());

  const findPump = (array: [], id: string): { id: string, name: string, fuelType: string } =>
    array?.find((pump: { id: string }) => pump?.id === id) ?? ({ id: '', name: '', fuelType: '' });

  const filteredPumps = pumps?.pumps?.filter((pump: { location: { id: number } }) => state.auth.user.location.id === pump.location.id);

  return (
    <MainLayout>
      <div className="flex min-h-full flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {/* <img className="mx-auto h-20 w-auto" src={Logo} alt="Your Company" /> */}
          <h1 className="text-center text-2xl font-bold leading-9 tracking-tight text-dark-blue">Purchase Fuel</h1>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Formik
            initialValues={initialValues}
            validationSchema={validator}
            onSubmit={onSubmit}
          >
            {
              ({
                values,
              }) => (
                <Form className="space-y-6">

                  <Select
                    name="id"
                    options={filteredPumps?.map((pump: { id: string, name: string, fuelType: string }) => ({ value: pump.id, label: `${pump.name} (${capitalize(pump.fuelType)})` }))}
                    label="Select Pump"
                    value={values.id ? { label: `${findPump(pumps?.pumps, values.id).name} (${capitalize(findPump(pumps?.pumps, values.id).fuelType)})`, value: findPump(pumps?.pumps, values.id).id } : null} />

                  <Input name="litres" label="Litres" type="text" />

                  <div>
                    <Button disabled={state.pump.isLoading} type="submit" >Purchase</Button>
                  </div>
                </Form>
              )
            }
          </Formik>
        </div>
      </div>
    </MainLayout>
  );
};

export default FuelPurchase;
