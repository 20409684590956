import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { checkExpiry } from '../helpers/decodeJwt';
import { State } from '../types/state';

const roles = [
  {
    name: 'hod',
    permissions: ['view', 'approve', 'edit']
  },
  {
    name: 'owner',
    permissions: ['view', 'approve', 'edit', 'delete', 'update', 'disburse']
  },
  {
    name: 'pump',
    permissions: ['view', 'disburse', 'request', 'apply']
  },
  {
    name: 'supervisor',
    permissions: ['view', 'approve']
  }
];

const PrivateRoute = ({ allowedRoles } : { allowedRoles: string[]}) => {
  const { isLoggedIn, user } = useSelector((state: State) => state.auth);
  const location = useLocation();
  const isExpired = checkExpiry(user.token);

  if (isExpired) return <Navigate to="/auth/sign-in" state={{ from: location }} replace />;

  if (!isLoggedIn) {
    return <Navigate to="/auth/sign-in" />;
  }
  console.log({ roles: user.role.permissions, state: allowedRoles.includes(user.role.permissions), allowedRoles})

  const checkRole = (name: string) => {
    const role = roles.find(role => role.name.toLowerCase() === name.toLowerCase());

    return allowedRoles.some(allowedRole => role?.permissions.includes(allowedRole));
  };

  if (!checkRole(user.role.name)) {
    return <Navigate to="/unathorised" replace />;
  }
  return <Outlet />;
};

PrivateRoute.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
};

export default PrivateRoute;