import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { editPump } from '../../api';
import Table from '../../components/Table';
import Button, { IconButton } from '../../components/Button';
import MainLayout from '../../layout/MainLayout';
import { toastMessage } from '../../utlls/toaster';
import { CrudModal } from '../../components/Modal';
import { PencilIcon } from '@heroicons/react/24/solid';
import { getPumps } from '../../api';
import { capitalize } from '../../utlls/common';
import Badge from '../../components/Badge';

type Pump = {
  id: string
  location: string
  litres: string
  fuelType: string
  name: string
}

const PumpList = () => {
  const state = useSelector((state: any) => state);
  const [pump, setPump] = useState<Pump | null>(null);
  const queryClient = useQueryClient();

  const { data: pumps } = useQuery({
    queryKey: ['pumps'],
    queryFn: () => getPumps(state.auth.user.token),
  });

  const onEdit = (data: Pump) => setPump(data);
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => setPump((prev) => {
    if (prev) return ({ ...prev, litres: e.target.value });

    return prev;
  });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!pump || !pump.id) return toastMessage('You cannot send an empty reason for rejecting the requisition.', 'error');
    try {
      const data = await editPump(state.auth.user.token, pump);

      toastMessage(data);
      setPump(null);
    } catch (error) {
      console.log(error);
    } finally {
      queryClient.invalidateQueries({ queryKey: ['pumps'] })
    }
  }

  const tHeadData: {
    title: string
    maxWidth: string
    align?: 'center' | 'left' | 'right'
  }[] =
    [
      {
        title: 'Location',
        maxWidth: 'auto'
      },
      {
        title: 'Last Updated By',
        maxWidth: 'auto'
      },
      {
        title: 'Litres',
        maxWidth: '200px'
      },
      {
        title: 'Fuel Type',
        maxWidth: 'auto',
        align: 'center'
      },
      {
        title: 'Actions',
        maxWidth: '200px',
        align: 'center'
      }
    ]

  const TBody = ({
    data
  }: {
    data: any
  }) => {
    const role = state.auth.user.role.name.toLowerCase();

    return (
      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th className="px-6 py-4 text-xs text-gray-900 whitespace-nowrap dark:text-white">
          {data?.name}
        </th>
        <th scope="row" className="px-6 py-4 text-xs font-medium text-gray-900 whitespace-nowrap dark:text-white">
          {data?.updatedBy}
        </th>
        <td className="px-6 py-4 text-xs">
          {data?.availableLitres}
        </td>
        <td className="px-6 py-4 text-xs text-center">
          {capitalize(data?.fuelType)}
        </td>
        <td className="px-6 py-4 text-xs text-center">
          {
            role === 'hod' ?
            <IconButton size='small' onClick={() => onEdit({
              id: data?.id,
              location: data?.location,
              litres: data?.litres,
              fuelType: data?.fuelType,
              name: data?.name
            })}>
              <PencilIcon width={18} height={18} />
            </IconButton> :
            <Badge text='No Actions Available' colors='bg-yellow-100 text-yellow-800' />
          }
        </td>
      </tr>
    )
  }

  const TBodyData = () => (
    <>
      {
        pumps &&
        pumps?.pumps?.map((item: any) => <TBody key={item?.id} data={item} />)
      }
    </>
  )

  return (
    <MainLayout>
      <CrudModal
        isOpen={Boolean(pump)}
        onClose={() => setPump(null)}
        title="Update Pump"
      >
        <form onSubmit={onSubmit} className="p-4 md:p-5">
          <div className="grid gap-4 mb-4 grid-cols-2">
            <div className="col-span-2">
              <label htmlFor="litres" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter The Number Of Litres</label>
              <input onChange={onInputChange} value={pump?.litres || ''} type="text" name="litres" id="litres" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Enter Litres" />
            </div>
          </div>
          <Button type='submit' label='Submit' colors='bg-blue-600 hover:bg-blue-400' />
        </form>
      </CrudModal>
      <Table tHeadData={tHeadData} tBodyData={<TBodyData />} />
    </MainLayout>
  )
}

export default PumpList