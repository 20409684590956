import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getUsers } from '../../api';
import Table from '../../components/Table';
import { IconButton } from '../../components/Button';
import MainLayout from '../../layout/MainLayout';
import Pagination from '../../components/Pagination';
import UpdateModal from './UpdateModal';
import { PencilIcon } from '@heroicons/react/24/solid';
import { RegisterUserValues } from '../../types/state';
import { SelectValue } from 'react-tailwindcss-select/dist/components/type';
import { substring } from '../../utlls/common';

const Users = () => {
  const state = useSelector((state: any) => state);
  const [data, setData] = useState<RegisterUserValues<SelectValue>>({
    id: '',
    firstName: '',
    lastName: '',
    role: '',
    email: '',
    // vehicle: '',
    pump: '',
    department: [],
    location: '',
    phoneNo: '',
    password: '',
    confirmPassword: '',
  });
  const [page, setPage] = useState<number>(1);

  const queryClient = useQueryClient();

  const { data: users } = useQuery({
    queryKey: ['users', page],
    queryFn: () => getUsers(state.auth.user.token, page),
  });

  const onUpdate = (data: any) => setData(data);

  const invalidateQueries = () => queryClient.invalidateQueries({ queryKey: ['users', page] });

  const tHeadData: {
    title: string
    maxWidth: string
    align?: 'center' | 'left' | 'right'
  }[] =
    [
      {
        title: 'Name',
        maxWidth: 'auto'
      },
      {
        title: 'E-Mail',
        maxWidth: 'auto'
      },
      {
        title: 'Phone No.',
        maxWidth: '200px'
      },
      {
        title: 'Role',
        maxWidth: '150px'
      },
      {
        title: 'Departments',
        maxWidth: '100px',
        align: 'center'
      },
      {
        title: 'Location',
        maxWidth: '100px',
        align: 'center'
      },
      {
        title: 'Actions',
        maxWidth: '100px',
        align: 'center'
      },
    ]

  const TBody = ({
    data
  }: {
    data: any
  }) => {
    return (
      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <th
          className="px-6 py-4 text-xs text-gray-900 whitespace-nowrap dark:text-white"
          dangerouslySetInnerHTML={{ __html: data?.name }}></th>
        <th scope="row" className="px-6 py-4 text-xs font-medium text-gray-900 whitespace-nowrap dark:text-white">
          {data?.email}
        </th>
        <td className="px-6 py-4 text-xs" dangerouslySetInnerHTML={{ __html: data?.phoneNo }}></td>
        <td className="px-6 py-4 text-xs text-center">
          {data?.role?.name}
        </td>
        <td className="px-6 py-4 text-xs text-center">
          {substring(data?.departments?.map((dep: any) => dep.name)?.join(', '), 30)}
        </td>
        <td className="px-6 py-4 text-xs text-center">
          {data?.location?.name}
        </td>
        <td className="px-6 py-4 text-xs flex gap-x-2 justify-center">
          <IconButton
            type="button"
            onClick={() => onUpdate({
              ...data,
              firstName: data?.name?.split(' ')[0],
              lastName: data?.name?.split(' ')[1],
              role: data?.role?.id,
              department: data?.deparments?.map((dep: any) => dep.id),
              location: data?.location?.id,
              password: '',
              confirmPassword: ''
            })}>
            <PencilIcon width={18} height={18} />
          </IconButton>
        </td>
      </tr>
    )
  }

  const TBodyData = () => (
    <>
      {
        users &&
        users?.users?.map((item: any) => <TBody key={item?.id} data={item} />)
      }
    </>
  )

  const setCurrentPage = (page: number | string) => setPage(Number(page));

  const setNextPage = () => {
    const pages = Math.ceil(users?.dataLength / 20) || 0;

    if (page < pages) setPage((prev) => prev + 1);
  };

  const setPrevPage = () => {
    if (page > 1) setPage((prev) => prev - 1);
  };

  const pages = Math.ceil(users?.dataLength / 20) || 0;

  return (
    <MainLayout>

      <UpdateModal
        data={data}
        isOpen={Boolean(data.id)}
        invalidateQueries={invalidateQueries}
        onClose={() => setData({
          id: '',
          firstName: '',
          lastName: '',
          role: '',
          email: '',
          // vehicle: '',
          pump: '',
          department: [],
          location: '',
          phoneNo: '',
          password: '',
          confirmPassword: '',
        })}
      />

      <Table tHeadData={tHeadData} tBodyData={<TBodyData />} />

      <div className="flex items-end justify-end mt-3">
        <Pagination
          currentPage={page}
          pages={pages}
          setNextPage={setNextPage}
          setPage={setCurrentPage}
          setPrevPage={setPrevPage}
        />
      </div>
    </MainLayout>
  )
}

export default Users