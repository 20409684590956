import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import config from '../config';

const firebaseConfig = {
  apiKey: "AIzaSyAs4--fGNSJh3fxYrQnEpjxKJp0ZHm7YAA",
  authDomain: "requisition-app-comply.firebaseapp.com",
  projectId: "requisition-app-comply",
  storageBucket: "requisition-app-comply.appspot.com",
  messagingSenderId: "811899674201",
  appId: "1:811899674201:web:62717186ba0e1d3cf1db48",
  measurementId: "G-H17BP981E5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

export const generateToken = async () => {
  const permission = await Notification.requestPermission();
  
  if (permission === 'granted') {
    const token = await getToken(messaging, {
      vapidKey: config.VAPID_KEY
    });
    
    return token;
  }

  return permission;
}
