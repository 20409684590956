import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toastMessage } from '../../utlls/toaster';

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const initialState = {
  isLoading: false,
  message: '',
  isError: false,
};

export const create = createAsyncThunk(
  'vehicle/create',
  async (payload: {
    name: string,
    engineCapacity: string,
    fuelTankCapacity: string,
    numberPlate: string,
    department: string,
    fuelType: string,
    fuelConsumption: string,
    fuelConsumptionUnits: string,
    mileage: string,
    token?: string
  }, thunkAPI) => {
    // Payload is an object that contains email, username, name and password keys.
    try {
      const resp = await request.post('/vehicle/create', {
        name: payload.name,
        engineCapacity: payload.engineCapacity,
        fuelTankCapacity: payload.fuelTankCapacity,
        numberPlate: payload.numberPlate,
        fuelConsumption: payload.fuelConsumption,
        fuelConsumptionUnits: payload.fuelConsumptionUnits,
        department: payload.department,
        fuelType: payload.fuelType,
        mileage: payload.mileage
      }, {
        headers: {
          Authorization: `Bearer ${payload?.token}`
        }
      });

      const msg = resp.data.msg;

      return msg;
    } catch (error: any) {
      if (error && 'response' in error) {
        if (error.response.status === 422) {
          return thunkAPI.rejectWithValue(error.response.data);
        }

        if (error.response.status === 500) return thunkAPI.rejectWithValue('Error: An internal server error has occured.');

        return thunkAPI.rejectWithValue(error.response.data);
      }
      return thunkAPI.rejectWithValue('Error: An unknown error has occured, please try again.');
    }
  },
);

const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, () => ({ isLoading: true, isError: false, message: '' }))
      .addCase(create.fulfilled, (state, { payload }) => {
        toastMessage(payload);

        return ({
          ...state,
          message: payload,
          isLoading: false,
        })
      })
      .addCase(create.rejected,
        (state, { payload }: any) => {
          if (payload) {
            toastMessage(payload.err, 'error');

            return ({
              ...state,
              isLoading: false,
            });
          }
        })
  },
});

export const vehicleReducer = vehicleSlice.reducer;
