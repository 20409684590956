import toast from "react-hot-toast"
import { CheckIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid'

export const toastMessage = (message: string, status: ('error' | 'success') = 'success') => {
  const iconClasses = status === "success" ? 'text-green-400 text-sm' : 'text-rose-400 text-sm';
  const Icon = status === 'success' ? CheckIcon : ExclamationTriangleIcon;
  if (status === 'success') {
    toast.success(message, {
      position: 'top-right',
      icon: <Icon className={iconClasses} width="24px"/>,
      duration: 5000
    });
  } else {
    toast.error(message, {
      position: 'top-right',
      icon: <Icon className={iconClasses} width="24px"/>,
      duration: 5000
    });
  }
}