import React from 'react';
import { CrudModal } from '../../components/Modal';
import { Form, Formik, FormikHelpers } from 'formik';
import Input from '../../components/Input';
import Select from '../../components/Select';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useQuery } from '@tanstack/react-query';
import { getLocations, getRoles } from '../../api';
import { object, ref, string } from 'yup';
import { RegisterUserValues } from '../../types/state';
import { SelectValue } from 'react-tailwindcss-select/dist/components/type';
import Button from '../../components/Button';
import { update } from '../../redux/user/userSlice';
import { toastMessage } from '../../utlls/toaster';

const UpdateModal = ({
  data,
  isOpen,
  invalidateQueries,
  onClose,
}: {
  data: RegisterUserValues<SelectValue>;
  isOpen: boolean;
  invalidateQueries: () => void;
  onClose: () => void
}) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const state = useSelector((state: any) => state);

  const { data: roles } = useQuery({
    queryFn: () => getRoles(state.auth.user.token),
    queryKey: ['roles']
  });

  // const { data: departments } = useQuery({
  //   queryFn: () => getDepartments(state.auth.user.token),
  //   queryKey: ['departments']
  // });

  const { data: locations } = useQuery({
    queryFn: () => getLocations(state.auth.user.token),
    queryKey: ['locations']
  });

  const validator = object({
    email: string()
      .email('Please enter a valid Email to continue.')
      .required('Email is a required field'),
    firstName: string()
      .required('Name is a required field'),
    lastName: string()
    .required('Other name is a required field'),
    phoneNo: string()
      .required('Phone number is a required field')
      .test({
        name: 'phone_no_test',
        test: (value: string) => /^(254|0)([7][0-9]|[1][0-1]){1}[0-9]{1}[0-9]{6}$/.test(value),
        message: 'Please enter the phone number in the following format: 254712345678'
      }),
    role: string()
      .required('Please select a role'),
    // department: array()
    //   .min(1, 'The minimum number of the array is one.'),
    location: string()
      .required('Please select a location.'),
    password: string(),
    confirmPassword: string()
      .oneOf([ref('password')], 'Your passwords do not match.'),
  });

  const onSubmit =
    (values: RegisterUserValues<SelectValue>, actions: FormikHelpers<RegisterUserValues<SelectValue>>) => {
      actions.setSubmitting(true);

      dispatch(update(Object.assign(values, { token: state.auth.user.token })))
        .then((res: any) => {
          res.type.includes('fulfilled') && onClose();
          res.type.includes('fulfilled') && actions.resetForm();
          res.type.includes('fulfilled') && actions.setSubmitting(false);
          res.type.includes('fulfilled') && invalidateQueries();
        })
        .catch((err: any) => {
          if (err && err.response) {
            return toastMessage(err && err.response.data.err, 'error');
          }

          toastMessage(err.message, 'error')
        });
    }

  const findLocation = (array: [], id: string): { id: string, name: string } =>
    array?.find((pump: { id: string }) => pump?.id === id) ?? ({ id: '', name: '' });

  return (
    <CrudModal
      isOpen={isOpen}
      onClose={onClose}
      title="Update User"
      width="max-w-[768px]"
    >
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[700px] pb-5">
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={validator}
          onSubmit={onSubmit}
        >
          {
            ({
              isSubmitting,
              values,
            }) => (
              <Form className="md:flex md:flex-wrap lg:flex lg:flex-wrap justify-between">

                <Input name="firstName" label="First Name" type="text" isFlex />

                <Input name="lastName" label="Last Name" type="text" isFlex />

                <Input name="email" type="email" label="E-mail" isFlex />

                <Input name="phoneNo" placeholder="254700000000" type="text" label="Phone Number" isFlex />

                <Select
                  name="role"
                  options={roles?.map((role: { id: string, name: string }) => ({ value: role.id, label: role.name }))}
                  label="Select Role"
                  value={values.role ? { label: roles?.find((role: { id: string }) => role?.id === values.role).name, value: values.role } : null}
                  isFlex />

                <Select
                  isFlex
                  name="location"
                  options={locations?.map((location: { name: string, id: string }) => ({ value: location.id, label: location.name }))}
                  label="Select a Location"
                  value={values.location ? { label: findLocation(locations, values.location).name, value: findLocation(locations, values.location).id } : null} />

                <Input name="password" type="password" label="Password" isFlex />

                <Input name="confirmPassword" type="password" label="Confirm Password" isFlex />

                <div className="w-full mt-5">
                  <Button disabled={isSubmitting} type="submit">Update Account</Button>
                </div>
              </Form>
            )
          }
        </Formik>
      </div>
    </CrudModal>
  )
}

export default UpdateModal