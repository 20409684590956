import React from 'react'

const Table = ({
  tHeadData,
  tBodyData,
}: {
  tHeadData: {
    title: string
    maxWidth: string
    align?: 'center' | 'left' | 'right'
  }[],
  tBodyData: React.ReactNode
}) => {
  return (
    <div className="relative overflow-x-auto">
      <table className="w-full min-w-[1200px] text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {
              tHeadData &&
              tHeadData?.map((tHead) => (<th scope="col" className="px-6 py-3" style={{ maxWidth: tHead.maxWidth, textAlign: tHead.align }}>{tHead.title}</th>))
            }
          </tr>
        </thead>
        <tbody>
          { tBodyData }
        </tbody>
      </table>
    </div>

  )
}

export default Table