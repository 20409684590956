import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import Logo from 'assets/imgs/transparent-logo.png';
import { Formik, Form, FormikHelpers } from 'formik';
import { login } from '../redux/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import { State } from '../types/state';
import { ThunkDispatch } from '@reduxjs/toolkit';
import Alert from '../components/Alert';
import Input from '../components/Input';

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const auth = useSelector((state: State) => state.auth);

  useEffect(() => {
    if (auth.isLoggedIn) navigate('/');
  }, [auth.isLoggedIn, navigate]);

  const validator = object({
    email: string()
      .required('Username is a required field'),
    password: string()
      .required('Password is a required field'),
  });

  const onSubmit = (values: { email: string, password: string }, actions: FormikHelpers<{ email: string, password: string}>) => dispatch(login(values))

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        {/* <img className="mx-auto h-20 w-auto" src={Logo} alt="Your Company" /> */}
        <h1 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-dark-blue">Sign In</h1>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={validator}
          onSubmit={onSubmit}
        >
          {
            ({
              values, errors, setFieldValue, getFieldMeta,
            }) => (
              <Form className="space-y-6">
                {
                  auth.message
                  && <Alert msg={auth.message} isError />
                }
                <Input label="Username" name="email" type="text" />

                <Input label="Password" name="password" type="password" />

                <div>
                  <button  type="submit" className={`flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${auth.isLoading ? 'cursor-not-allowed' : ''}`}>Sign in</button>
                </div>
                <div className="text-sm">
                  <Link to="/book-car/auth/forgot-password" className="font-semibold text-light-green hover:text-green-400">Forgot password?</Link>
                </div>
              </Form>
            )
          }
        </Formik>

        <p className="mt-10 text-center text-sm text-gray-500">
          Not a member?
          <Link to="/auth/register" className="mx-1.5 font-semibold leading-6 text-light-green hover:text-green-400">Register</Link>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
