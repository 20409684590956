import React from 'react';
// import Logo from 'assets/imgs/transparent-logo.png';
import { Formik, Form, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import { ThunkDispatch } from '@reduxjs/toolkit';
import Input from '../components/Input';
import { create } from '../redux/roles/rolesSlice';
// import Checkbox from '../components/Checkbox';
import MainLayout from '../layout/MainLayout';


const Role = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const state = useSelector((state: any) => state);
  // const permissions = ['disburse', 'approve', 'apply', 'view', 'create', 'delete']
  const initialValues: { name: string } = {
    name: '',
    // permissions: []
  }

  const validator = object({
    name: string()
      .required('Role name is a required field'),
    // permissions: array(),
  });

  // const updatePermissions = (name: string, value: string, values: string[], setFieldValue: (name: string, value: string[]) => void) => {
  //   const includes = values.includes(value);
  //   const newValues = includes ? values.filter((filtered: string) => value.toLowerCase() !== filtered.toLowerCase()) : [...values, value];
  //   setFieldValue(name, newValues);
  // }

  const onSubmit =
    (values: { name: string }, actions: FormikHelpers<{ name: string }>) =>
      dispatch(create(Object.assign(values, { token: state.auth.user.token })))
        .then((res) => res.type.includes('fulfilled') && actions.resetForm())

  return (
    <MainLayout>
      <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {/* <img className="mx-auto h-20 w-auto" src={Logo} alt="Your Company" /> */}
          <h1 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-dark-blue">Create Role</h1>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Formik
            initialValues={initialValues}
            validationSchema={validator}
            onSubmit={onSubmit}
          >
            {
              ({
                values, setFieldValue,
              }) => (
                <Form className="space-y-6">

                  <Input name="name" label="Role Name" type="text" />

                  {/* {
                    permissions &&
                    permissions.map((permission, key) =>
                      <Checkbox
                        key={key}
                        name={permission}
                        onChange={(value) => updatePermissions('permissions', value, values.permissions, setFieldValue)}
                        isChecked={values.permissions.includes(permission)}
                        value={permission}
                        label={permission} />)
                  } */}

                  <div>
                    <button disabled={state.role.isLoading} type="submit" className={`flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${state.role.isLoading ? 'cursor-not-allowed' : ''}`}>Create</button>
                  </div>
                </Form>
              )
            }
          </Formik>
        </div>
      </div>
    </MainLayout>
  );
};

export default Role;
