import React from 'react';
// import Logo from 'assets/imgs/transparent-logo.png';
import { Formik, Form, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import { ThunkDispatch } from '@reduxjs/toolkit';
import Input from '../../components/Input';
import { create } from '../../redux/pump/pumpSlice';
import MainLayout from '../../layout/MainLayout';
import Select from '../../components/Select';
import { useQuery } from '@tanstack/react-query';
import { getLocations } from '../../api';


const Pump = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const { data: locations } = useQuery({
    queryFn: () => getLocations(state.auth.user.token),
    queryKey: ['locations']
  });

  const state = useSelector((state: any) => state);
  const initialValues: {
    fuelType: string,
    location: string,
    litres: string,
    name: string,
  } = {
    fuelType: '',
    location: '',
    litres: '',
    name: ''
  }

  const validator = object({
    name: string()
      .required('Pump name is a required field.'),
    fuelType: string()
      .required('Fuel type is a required field.'),
    location: string()
      .required('Please select a location.'),
    litres: string(),
  });

  const onSubmit =
    (values: {
      fuelType: string,
      location: string,
      litres: string,
      name: string
    }, actions: FormikHelpers<{
      fuelType: string,
      location: string,
      litres: string,
      name: string
    }>) =>
      dispatch(create(Object.assign(values, { token: state.auth.user.token })))
        .then((res) => res.type.includes('fulfilled') && actions.resetForm())

  const fuelTypes = [
    { value: 'diesel', label: 'Diesel' },
    { value: 'petrol', label: 'Petrol' },
  ];

  const findLocation = (array: [], id: string): { id: string, name: string } =>
    array?.find((pump: { id: string }) => pump?.id === id) ?? ({ id: '', name: '' })

  return (
    <MainLayout>
      <div className="flex min-h-full flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          {/* <img className="mx-auto h-20 w-auto" src={Logo} alt="Your Company" /> */}
          <h1 className="text-center text-2xl font-bold leading-9 tracking-tight text-dark-blue">Create Pump</h1>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Formik
            initialValues={initialValues}
            validationSchema={validator}
            onSubmit={onSubmit}
          >
            {
              ({
                values,
              }) => (
                <Form className="space-y-6">
                  
                  <Input name="name" label="Pump Name" type="text" />

                  <Select
                    name="location"
                    options={locations?.map((location: { name: string, id: string }) => ({ value: location.id, label: location.name }))}
                    label="Select a Location"
                    value={values.location ? { label: findLocation(locations, values.location).name, value: findLocation(locations, values.location).id } : null} />

                  <Input name="litres" label="Litres" type="text" />

                  <Select
                    name="fuelType"
                    options={fuelTypes}
                    label="Select Fuel Type"
                    value={values.fuelType ? fuelTypes.find(item => item.value === values.fuelType) || {label: values.fuelType, value: values.fuelType} : null} />

                  <div>
                    <button disabled={state.pump.isLoading} type="submit" className={`flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${state.pump.isLoading ? 'cursor-not-allowed' : ''}`}>Create</button>
                  </div>
                </Form>
              )
            }
          </Formik>
        </div>
      </div>
    </MainLayout>
  );
};

export default Pump;
