import React from 'react'

const Badge = ({
  colors =  'bg-blue-100 text-blue-800',
  text,
  size = 'medium'
} : {
  colors?: string,
  text: string,
  size?: 'small' | 'medium' | 'large' 
}) => {
  const sizes = size === 'small' ?
    'text-xs px-2 py-0.5' :
    size === 'medium'  ?
      'text-sm px-2.5 py-0.5' :
      'text-base px-2.5 py-0.5'
  return (
    <span className={`${colors} ${sizes} font-medium me-2 rounded dark:bg-blue-900 dark:text-blue-300`}>{text}</span>
  )
}

export default Badge