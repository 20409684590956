import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { checkExpiry } from '../../helpers/decodeJwt';
import { RegisterValues } from '../../types/state';

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const initialState = {
  user: {
    id: '',
    email: '',
    username: '',
    role: '',
    location: ''
  },
  isLoggedIn: false,
  isLoading: false,
  message: '',
  token: ''
};

export const login = createAsyncThunk(
  'auth/login',
  async (payload: { email: string, password: string }, thunkAPI) => {
    // Payload is an object that contains email and password keys.
    try {
      const resp = await request.post('/auth/sign_in', payload);

      const user = resp.data.user;
      const token = resp.data.token;
      user.token = token;
      return user;
    } catch (error: any) {
      if (error && 'response' in error) {
        if (error.response.status === 401) {
          return thunkAPI.rejectWithValue(error.response.data);
        }

        if (error.response.status === 500) return thunkAPI.rejectWithValue('Error: An internal server error has occured.');
      }
      return thunkAPI.rejectWithValue('Error: An unknown error has occured, please try again.');
    }
  },
);

export const register = createAsyncThunk(
  'auth/signup',
  async (payload: RegisterValues, thunkAPI) => {
    // Payload is an object that contains email, username, name and password keys.
    try {
      const resp = await request.post('auth/sign_up', payload);

      const user = resp.data.user;
      const token = resp.headers.token;
      user.token = token;
      return user;
    } catch (error: any) {
      if (error && 'response' in error) {
        if (error.response.status === 422) {
          return thunkAPI.rejectWithValue(error.response.data.status.message);
        }

        if (error.response.status === 500) return thunkAPI.rejectWithValue('Error: An internal server error has occured.');
      }
      return thunkAPI.rejectWithValue('Error: An unknown error has occured, please try again.');
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateLogin: (state) => {
      const isExpired = checkExpiry(state.token);

      if (isExpired) {
        return {
          ...state,
          user: {
            id: '',
            email: '',
            username: '',
            role: '',
            location: ''
          },
          isLoggedIn: false,
        };
      }

      return { ...state };
    },
    logout: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => ({ ...state, isLoading: true, message: '' }))
      .addCase(login.fulfilled, (state, { payload }) => ({
        ...state,
        isLoggedIn: true,
        user: { ...payload },
        isLoading: false,
      }))
      .addCase(login.rejected,
        (state, { payload } : any) => {
          if (payload) {
            return ({ ...state, isLoading: false, message: payload.err })
          }
        })
      .addCase(register.pending, (state) => ({ ...state, isLoading: true, message: '' }))
      .addCase(register.fulfilled, (state, { payload }) => ({
        ...state,
        isLoggedIn: true,
        user: { ...payload },
        isLoading: false,
      }))
      .addCase(register.rejected,
        (state, { payload } : any) => {
          if (payload) {
            return ({ ...state, isLoading: false, message: payload.err })
          }
        });
  },
});

export const { logout, updateLogin } = authSlice.actions;

export const authReducer = authSlice.reducer;
