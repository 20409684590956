import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RequisitionValues } from '../../types/state';
import { toastMessage } from '../../utlls/toaster';

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const initialState = {
  isLoading: false,
  message: '',
  isError: false,
};

export const create = createAsyncThunk(
  'requisition/create',
  async (payload: RequisitionValues & { token: string }, thunkAPI) => {
    // Payload is an object that contains email, username, name and password keys.
    try {
      const resp = await request.post('/requisition/create', {
        driverName: payload.driverName,
        driverNumber: payload.driverNumber,
        litres: payload.litres,
        destination: payload.destination,
        distanceCoverage: payload.distanceCoverage,
        trackingMileage: payload.trackingMileage,
        closingMileage: payload.closingMileage,
        openingMileage: payload.openingMileage,
        vehicle: payload.vehicle,
        pump: payload.pump,
        department: payload.department,
        notes: payload.remarks,
        tripType: payload.tripType
      }, {
        headers: {
          Authorization: `Bearer ${payload?.token}`
        }
      });

      const msg = resp.data.msg;

      return msg;
    } catch (error: any) {
      if (error && 'response' in error) {
        if (error.response.status === 500) return thunkAPI.rejectWithValue('Error: An internal server error has occured.');

        return thunkAPI.rejectWithValue(error.response.data);
      }
      return thunkAPI.rejectWithValue('Error: An unknown error has occured, please try again.');
    }
  },
);

const requistionSlice = createSlice({
  name: 'requisition',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(create.pending, () => ({ isLoading: true, isError: false, message: '' }))
      .addCase(create.fulfilled, (state, { payload }) => {
        toastMessage(payload);

        return ({
          ...state,
          message: payload,
          isLoading: false,
        })
      })
      .addCase(create.rejected,
        (_, { payload } : any) => {
          if (payload) {
            toastMessage(payload.err, 'error');
            return ({ isLoading: false, message: payload.err, isError: true })
          }
        })
  },
});

export const requisitionReducer = requistionSlice.reducer;
