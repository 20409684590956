import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import Logo from 'assets/imgs/transparent-logo.png';
import { Formik, Form, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { object, ref, string } from 'yup';
import { RegisterValues, State } from '../types/state';
import Alert from '../components/Alert';
import { register } from '../redux/auth/authSlice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import Input from '../components/Input';

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const auth = useSelector((state: State) => state.auth);

  useEffect(() => {
    if (auth.isLoggedIn) navigate('/');
  }, [auth.isLoggedIn, navigate]);

  const validator = object({
    email: string()
      .email('Please enter a valid Email to continue.')
      .required('Email is a required field'),
    firstName: string()
      .required('Name is a required field'),
    lastName: string()
      .required('Username is a required field'),
    phoneNo: string()
      .required('Username is a required field'),
    password: string()
      .required('Password is a required field'),
    confirmPassword: string()
      .oneOf([ref('password')], 'Your passwords do not match.'),
  });

  const onSubmit = (values: RegisterValues, actions: FormikHelpers<RegisterValues>) => dispatch(register(values))

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        {/* <img className="mx-auto h-20 w-auto" src={Logo} alt="Your Company" /> */}
        <h1 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-dark-blue">Register</h1>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phoneNo: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={validator}
          onSubmit={onSubmit}
        >
          {
            ({
              values, errors, setFieldValue, getFieldMeta,
            }) => (
              <Form className="space-y-6">
                {
                  auth.message
                  && <Alert msg={auth.message} isError />
                }

                <Input name="firstName" label="First Name" type="text" />

                <Input name="lastName" label="Last Name" type="text" />

                <Input name="email" type="email" label="E-mail" />

                <Input name="phoneNo" type="text" label="Phone Number" />

                <Input name="password" type="password" label="Password" />

                <Input name="confirmPassword" type="password" label="Confirm Password" />
               
                <div>
                  <button disabled={auth.isLoading} type="submit" className={`flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${auth.isLoading ? 'cursor-not-allowed' : ''}`}>Register</button>
                </div>
                <div className="text-sm">
                  <Link to="/book-car/auth/forgot-password" className="font-semibold text-light-green hover:text-green-400">Forgot password?</Link>
                </div>
              </Form>
            )
          }
        </Formik>

        <p className="mt-10 text-center text-sm text-gray-500">
          Already a member?
          <Link to="/auth/sign-in" className="mx-1.5 font-semibold leading-6 text-light-green hover:text-green-400">Login</Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
