import React from 'react';
// import Logo from 'assets/imgs/transparent-logo.png';
import { Formik, Form, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { number, object, string } from 'yup';
import Input from '../components/Input';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { create } from '../redux/requisition/requisitionSlice';
import Button from '../components/Button';
import { useQuery } from '@tanstack/react-query';
import { getDepartments, getPumps, getVehicles } from '../api';
import Select from '../components/Select';
import { RequisitionValues } from '../types/state';
import MainLayout from '../layout/MainLayout';
import { capitalize } from '../utlls/common';
import TextArea from '../components/TextArea';

const Requisition = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const state = useSelector((state: any) => state);

  const { data } = useQuery({
    queryKey: ['vehicles'],
    queryFn: () => getVehicles(state.auth.user.token)
  });

  const { data: pumps } = useQuery({
    queryFn: () => getPumps(state.auth.user.token),
    queryKey: ['pumps']
  });

  const { data: departments } = useQuery({
    queryFn: () => getDepartments(state.auth.user.token),
    queryKey: ['departments']
  });

  const validator = object({
    department: string()
      .required('Please select a department.'),
    distanceCoverage: number()
      .typeError('Please enter a valid number')
      .required('Please enter the distance covered.'),
    destination: string()
      .required('Please enter the destination.'),
    driverName: string()
      .required('Please enter the driver name.'),
    driverNumber: string()
      .required('Please enter the driver number.'),
    litres: string()
      .required('Please enter the amount of litres.'),
    vehicle: string()
      .required('Please select a vehicle.'),
    openingMileage: number()
      .typeError('Please enter a valid number'),
    closingMileage: number()
      .typeError('Please enter a valid number.')
      .required('Please enter the closing mileage'),
    pump: string()
      .required('Please select a pump.'),
    remarks: string(),
    tripType: string()
      .required('Please select a trip type')
  });

  const onSubmit =
    (values: RequisitionValues, actions: FormikHelpers<RequisitionValues>) =>
      dispatch(create(Object.assign(values, { token: state.auth.user.token })))
        .then((res) => res.type.includes('fulfilled') && actions.resetForm());

  const selectOptions = data ?
    data
      ?.map((vehicle: { numberPlate: string, id: string, fuelType: string }) =>
        ({ label: `${vehicle.numberPlate} (${capitalize(vehicle.fuelType)})`, value: vehicle.id })) : [];

  const findVehicle = (array: [], id: string): { id: string, numberPlate: string, fuelType: string, mileage: string } =>
    array.find((value: { id: string, numberPlate: string, fuelType: string }) => value.id === id) ?? ({ id: '', numberPlate: '', fuelType: '', mileage: '' });

  const findPump = (array: [], id: string): { id: string, name: string, fuelType: string } =>
    array?.find((pump: { id: string }) => pump?.id === id) ?? ({ id: '', name: '', fuelType: '' });

  const calcNetKms = (openingMileage: string, closingMileage: string): string => {
    const opening = openingMileage ? Number(openingMileage) : 0;
    const closing = closingMileage ? Number(closingMileage) : 0;

    if (opening > closing) return '0';

    return `NET: ${closing - opening} Kms`;
  }

  const filteredPumps = pumps?.pumps?.filter((pump: { location: { id: number } }) => pump.location.id === state.auth.user.location.id);

  const filteredDepartments = departments?.filter((department: { locationId: number }) => department.locationId === state.auth.user.location.id);

  const findDepartment = (array: [], id: string): { id: string, name: string } =>
    array?.find((department: { id: string }) => department?.id === id) ?? ({ id: '', name: '' });

  return (
    <MainLayout>
      <div className="flex min-h-full flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          {/* <img className="mx-auto h-20 w-auto" src={Logo} alt="Your Company" /> */}
          <h1 className="mt-3 text-center text-2xl font-bold leading-9 tracking-tight text-dark-blue">Create Requisition</h1>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[700px]">
          <Formik
            initialValues={{
              driverName: '',
              driverNumber: '',
              vehicle: '',
              litres: '',
              destination: '',
              distanceCoverage: 0,
              closingMileage: '',
              trackingMileage: '',
              tripType: '',
              pump: '',
              department: '',
              remarks: ''
            }}
            validationSchema={validator}
            onSubmit={onSubmit}
          >
            {
              ({
                values,
                setFieldValue
              }) => (
                <Form className="md:flex md:flex-wrap lg:flex lg:flex-wrap justify-between">

                  <Input name="driverName" label="Driver Name" type="text" isFlex />

                  <Input name="driverNumber" label="Driver Number" type="text" isFlex />

                  <div className="flex mt-2 sm:w-full md:w-[48%] lg:w-[48%] items-end justify-between">
                    <Select
                      value={values.vehicle ? { label: `${findVehicle(data, values.vehicle).numberPlate} (${capitalize(findVehicle(data, values.vehicle).fuelType)})`, value: values.vehicle } : null}
                      options={selectOptions}
                      name='vehicle'
                      label='Vehicle'
                      flexClasses="w-[68%]"
                      isFlex />
                    <span className="w-[28%] text-sm text-emerald-500">{values.closingMileage && calcNetKms(findVehicle(data, values.vehicle).mileage, values.closingMileage)}</span>
                  </div>


                  <Input name="closingMileage" label="Closing Mileage" type="text" isFlex />

                  {
                    values.vehicle && Number(findVehicle(data, values.vehicle)?.mileage) < 1 &&
                    <Input name="openingMileage" label="Opening Mileage" type="text" isFlex />
                  }

                  <Select
                    name="department"
                    options={filteredDepartments?.map((department: { name: string, id: string }) => ({ value: department.id, label: department.name }))}
                    label="Select a Department"
                    value={values.department ? { label: findDepartment(departments, values.department).name, value: findDepartment(departments, values.department).id } : null}
                    isFlex />

                  <Input name="destination" label="Destination" type="text" isFlex />

                  <Select
                    name="pump"
                    options={filteredPumps?.map((pump: { id: string, name: string, fuelType: string }) => ({ value: pump.id, label: `${pump.name} (${capitalize(pump.fuelType)})` }))}
                    label="Select Pump"
                    value={values.pump ? { label: `${findPump(pumps?.pumps, values.pump).name} (${capitalize(findPump(pumps?.pumps, values.pump).fuelType)})`, value: findPump(pumps?.pumps, values.pump).id } : null}
                    isFlex />

                  <Select
                    name="tripType"
                    options={[{ value: 'One Way', label: 'One Way' }, { value: 'Two Way', label: 'Two Way' }]}
                    label="Select Trip Type"
                    value={values.tripType ? { label: values.tripType, value: values.tripType } : null}
                    isFlex />

                  <Input name="litres" label="Litres" type="text" isFlex />

                  <Input name="distanceCoverage" label="Distance Covered" type="text" isFlex />

                  <TextArea
                    htmlFor='remarks'
                    onChange={(value) => setFieldValue('remarks', value)}
                    label="Remarks"
                    placeholder='Remarks'
                    rows={4}
                  />

                  <div className="w-full mt-5">
                    <Button type="submit" label="Create Requisition" disabled={state.requisition.isLoading} />
                  </div>
                </Form>
              )
            }
          </Formik>
        </div>
      </div>
    </MainLayout>
  );
};

export default Requisition;
