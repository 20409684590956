import React from "react";
import PropTypes from 'prop-types';

const Alert = ({
  isError,
  msg,
} : {
  isError: boolean,
  msg: string
}) => (
  <div className={`border-l-4 p-4 ${isError ? 'bg-red-100 border-red-500 text-black' : 'bg-green-100 border-green-500 text-black'}`} role="alert">
    <p>{msg}</p>
  </div>
);

Alert.propTypes = {
  isError: PropTypes.bool,
  msg: PropTypes.string,
};

Alert.defaultProps = {
  isError: false,
  msg: '',
};

export default Alert;