import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { authReducer } from './auth/authSlice';
import { userReducer } from './user/userSlice';
import { roleReducer } from './roles/rolesSlice';
import { vehicleReducer } from './vehicle/vehicleSlice';
import { requisitionReducer } from './requisition/requisitionSlice';
import { pumpReducer } from './pump/pumpSlice';
import { locationReducer } from './location/locationSlice';
import { departmentReducer } from './department/departmentSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  role: roleReducer,
  vehicle: vehicleReducer,
  requisition: requisitionReducer,
  pump: pumpReducer,
  location: locationReducer,
  department: departmentReducer
});

const persistConfig = {
  key: 'root',
  whitelist: ['auth'],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export default store;
