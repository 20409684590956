import React from 'react'
import Navbar from '../components/Header'

const MainLayout = ({ children } : { children: React.ReactNode }) => {
  return (
    <>
      <Navbar />
      <div className="mx-8 mt-3 mb-8">{children}</div>
    </>
  )
}

export default MainLayout