import React from 'react';
// import Logo from 'assets/imgs/transparent-logo.png';
import { Formik, Form, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { array, object, ref, string } from 'yup';
import { RegisterUserValues } from '../../types/state';
import Input from '../../components/Input';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { useQuery } from '@tanstack/react-query';
import Select from '../../components/Select';
import { create } from '../../redux/user/userSlice';
import MainLayout from '../../layout/MainLayout';
import { getDepartments, getLocations, getRoles } from '../../api';
import TWSelect from 'react-tailwindcss-select';
import { SelectValue } from 'react-tailwindcss-select/dist/components/type';

const User = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const state = useSelector((state: any) => state);

  const { data: roles } = useQuery({
    queryFn: () => getRoles(state.auth.user.token),
    queryKey: ['roles']
  });

  const { data: departments } = useQuery({
    queryFn: () => getDepartments(state.auth.user.token),
    queryKey: ['departments']
  });

  const { data: locations } = useQuery({
    queryFn: () => getLocations(state.auth.user.token),
    queryKey: ['locations']
  });

  const validator = object({
    email: string()
      .email('Please enter a valid Email to continue.')
      .required('Email is a required field'),
    firstName: string()
      .required('Name is a required field'),
    lastName: string()
      .required('Other name is a required field'),
    phoneNo: string()
      .required('Phone number is a required field')
      .test({
        name: 'phone_no_test',
        test: (value: string) => /^(254|0)([7][0-9]|[1][0-1]){1}[0-9]{1}[0-9]{6}$/.test(value),
        message: 'Please enter the phone number in the following format: 254712345678'
      }),
    role: string()
      .required('Please select a role'),
    department: array()
      .min(1, 'The minimum number of the array is one.')
      .required('Please select a department.'),
    location: string()
      .required('Please select a location.'),
    password: string()
      .required('Password is a required field'),
    confirmPassword: string()
      .oneOf([ref('password')], 'Your passwords do not match.'),
  });

  const onSubmit =
    (values: RegisterUserValues<SelectValue>, actions: FormikHelpers<RegisterUserValues<SelectValue>>) =>
      dispatch(create(Object.assign(values, { token: state.auth.user.token })))
        .then((res: any) => res.type.includes('fulfilled') && actions.resetForm());

  const findLocation = (array: [], id: string): { id: string, name: string } =>
    array?.find((pump: { id: string }) => pump?.id === id) ?? ({ id: '', name: '' });

  // const findDepartment = (array: [], id: string): { id: string, name: string } =>
  //   array?.find((department: { id: string }) => department?.id === id) ?? ({ id: '', name: '' });

  return (
    <MainLayout>
      <div className="flex min-h-full flex-col justify-center px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {/* <img className="mx-auto h-20 w-auto" src={Logo} alt="Your Company" /> */}
          <h1 className="text-center text-2xl font-bold leading-9 tracking-tight text-dark-blue">Create User</h1>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[700px]">
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              role: '',
              email: '',
              // vehicle: '',
              pump: '',
              department: [],
              location: '',
              phoneNo: '',
              password: '',
              confirmPassword: '',
            }}
            validationSchema={validator}
            onSubmit={onSubmit}
          >
            {
              ({
                values,
                getFieldMeta,
                setFieldValue
              }) => (
                <Form className="md:flex md:flex-wrap lg:flex lg:flex-wrap justify-between">

                  <Input name="firstName" label="First Name" type="text" isFlex/>

                  <Input name="lastName" label="Last Name" type="text" isFlex/>

                  <Input name="email" type="email" label="E-mail" isFlex/>

                  <Input name="phoneNo" type="text" label="Phone Number" isFlex/>

                  <Select
                    name="role"
                    options={roles?.map((role: { id: string, name: string }) => ({ value: role.id, label: role.name }))}
                    label="Select Role"
                    value={values.role ? { label: roles?.find((role: { id: string }) => role?.id === values.role).name, value: values.role } : null}
                    isFlex />

                  <div className='mt-2 sm:w-full md:w-[48%] lg:w-[48%]'>
                    <label htmlFor="department" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Department</label>
                    <TWSelect
                      isMultiple
                      primaryColor="blue"
                      onChange={(value: SelectValue) => {
                        console.log(value)
                        const result = value && Array.isArray(value) && value;
                        setFieldValue('department', result);
                      }}
                      options={departments?.map((department: { name: string, id: string }) => ({ value: department.id, label: department.name }))}
                      value={Array.isArray(values.department) && values.department.length > 0 ? values.department : null} 
                      />
                    <small className="text-sm mt-0.5 text-red-400">{getFieldMeta('department').touched && getFieldMeta('department').error}</small>
                  </div>
                    
                  <Select
                    name="location"
                    options={locations?.map((location: { name: string, id: string }) => ({ value: location.id, label: location.name }))}
                    label="Select a Location"
                    value={values.location ? { label: findLocation(locations, values.location).name, value: findLocation(locations, values.location).id } : null} />

                  <Input name="password" type="password" label="Password" isFlex/>

                  <Input name="confirmPassword" type="password" label="Confirm Password" isFlex/>

                  <div className="w-full mt-5">
                    <button disabled={state.user.isLoading} type="submit" className={`flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${state.user.isLoading ? 'cursor-not-allowed' : ''}`}>Create Account</button>
                  </div>
                </Form>
              )
            }
          </Formik>
        </div>
      </div>
    </MainLayout>
  );
};

export default User;
